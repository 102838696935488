<template>
  <v-container pb-0 px-0 pt-0 class="PlanejamentoAcaoDadosAgrupamento">
    <v-row align="center" justify="center" row fill-height>
      <button @click="agrupaPorTipoAcao"
        v-bind:class="{ selecionado: botaoSelecionado('tipoAcao') }">
        <v-icon>content_paste</v-icon>
        <span>{{ $tc('label.tipo_acao', 1) }}</span>
      </button>
      <button @click="agrupaPorFornecedor"
        v-bind:class="{ selecionado: botaoSelecionado('fornecedor') }">
        <v-icon>fa-industry</v-icon>
        <span>{{ $tc('label.fornecedor', 1) }}</span>
      </button>
      <button v-for="(d, index) in dependenciasFornecedor"
        @click="agrupaPorExtensao(d.label)"
        :key="index"
        v-bind:class="{ selecionado: botaoSelecionado(d.label) }">
        <v-icon>group_work</v-icon>
        <span>{{d.nome}}</span>
      </button>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      botoesAgrupamento: {},
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getFornecedorMetadado',
    ]),
    dependenciasFornecedor() {
      if (!this.getFornecedorMetadado) {
        return [];
      }

      const { mapaEntidades } = this.getFornecedorMetadado;
      return Object.values(mapaEntidades)
        .map((e) => ({ nome: e.entidadeEstrangeira, label: e.label }));
    },
  },
  methods: {
    refazBusca() {
      this.$emit('PlanejamentoAcaoDadosAgrupamento_Alterado');
    },
    agrupaPorTipoAcao() {
      this.desagrupaOutros('tipoAcao');
      this.refazBusca();
    },
    agrupaPorFornecedor() {
      this.desagrupaOutros('fornecedor');
      this.refazBusca();
    },
    agrupaPorExtensao(labelExtensao) {
      this.desagrupaOutros(labelExtensao);
      this.refazBusca();
    },
    desagrupaOutros(labelAgrupamento) {
      Object.keys(this.botoesAgrupamento)
        .filter((k) => k !== labelAgrupamento)
        .forEach((k) => {
          this.botoesAgrupamento[k] = false;
        });
      this.botoesAgrupamento[labelAgrupamento] = true;

      setTimeout(() => this.$forceUpdate());
    },
    botaoSelecionado(nomeBotao) {
      return this.botoesAgrupamento[nomeBotao];
    },
    registraBotoesAgrupamento() {
      this.botoesAgrupamento.tipoAcao = true;

      this.botoesAgrupamento.fornecedor = false;
      this.dependenciasFornecedor.forEach((d) => {
        this.botoesAgrupamento[d.label] = false;
      });

      setTimeout(() => this.$forceUpdate());
    },
    agrupamentoHabilitado() {
      const agrupamento = Object.keys(this.botoesAgrupamento)
        .filter((k) => this.botoesAgrupamento[k])[0];
      return agrupamento;
    },
  },
};
</script>
<style>
.PlanejamentoAcaoDadosAgrupamento {
  width: 100%;
  max-width: initial;
}
.PlanejamentoAcaoDadosAgrupamento i,
.PlanejamentoAcaoDadosAgrupamento span {
  width: 100%;
}
.PlanejamentoAcaoDadosAgrupamento button {
  margin-right: 10px;
  padding: 4px;
  font-size: 0.7em;
}
.PlanejamentoAcaoDadosAgrupamento button.selecionado {
  color: var(--v-primary-darken1);
}
.PlanejamentoAcaoDadosAgrupamento button.selecionado i {
  color: var(--v-primary-darken1);
}
.PlanejamentoAcaoDadosAgrupamento button:not(.selecionado):hover {
  color: var(--v-grey-darken1);
}
.PlanejamentoAcaoDadosAgrupamento button:not(.selecionado):hover i {
  color: var(--v-grey-darken1);
}
</style>
