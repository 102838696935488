<template>
  <v-dialog v-model="showModalMultiplasNds" :persistent="true" max-width="55%">
    <v-card style="min-height:168px; height: 100%;">
      <v-card-title class="justify-center" style="word-break: break-word; width: 97%; text-align: center;">
        {{ $tc('label.valor_alocado', 1) }}: {{ getMoney(valorTotal) }}
      </v-card-title>
      <v-card-text>
        <v-container fluid px-0 py-0>
          <v-row>
            <v-col cols="12" >
              <v-data-table
                :headers="headers"
                :items="listaMultiplasNds"
                hide-default-footer
                disable-pagination
                :key="tableKey"
                :items-per-page="-1"
                :no-data-text="$t('message.nenhum_registro')"
                style="overflow:hidden">
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-center">
                      <span v-text="item.num_nota_debito"></span>
                    </td>
                    <td class="text-center">
                      <span v-text="item.cod_referencia_nd_fornecedor"></span>
                    </td>
                    <td class="text-center">
                      <span v-text="convertToMoney(item.valor_nd)"></span>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="close">{{ $t('label.fechar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  convertToMoney,
} from '@/common/functions/numeric';
import { getMoney } from '@/common/functions/helpers';

export default {
  data() {
    return {
      showModalNovaNd: false,
      showModalMultiplasNds: false,
      tableKey: 1,
      listaMultiplasNds: [],
      headers: [
        {
          text: this.$t('label.numero_nd'), value: 'numero_nd', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.cod_referencia_nd_fornecedor'), value: 'cod_referencia_nd_fornecedor', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.valor_nd'), value: 'valor_nd', sortable: false, align: 'center',
        },
      ],
    };
  },
  computed: {
    valorTotal() {
      return this.listaMultiplasNds.reduce((a, b) => a + b.valor_nd, 0);
    },
  },
  methods: {
    convertToMoney,
    getMoney,
    open(item) {
      if (item.lista_multiplas_nds && item.lista_multiplas_nds.length) {
        this.listaMultiplasNds = [...item.lista_multiplas_nds];
      }
      this.ordenarLista();
      this.showModalMultiplasNds = true;
    },
    close() {
      this.resetar();
      this.showModalMultiplasNds = false;
    },
    resetar() {
      this.listaMultiplasNds = [];
    },
    cancelar() {
      this.close();
    },
    fecharModalAdicionarNd() {
      this.showModalNovaNd = false;
    },
    ordenarLista() {
      function compare(a, b) {
        if (!!a.num_nota_debito && !b.num_nota_debito) return -1;
        if (!!b.num_nota_debito && !a.num_nota_debito) return 1;
        if (!a.id && !b.id) return 0;
        if (!a.id) return 1;
        if (!b.id) return -1;
        return a.id - b.id;
      }
      this.listaMultiplasNds.sort(compare);
    },
  },
};
</script>
