<style>
  .PlanejamentoAcaoList__Modal {
    text-align: left;
    display: inline-block;
  }
  .PlanejamentoAcaoList__Modal .headline {
    width: 100%;
    text-align: center;
  }
  .PlanejamentoAcaoList__Modal_Div {
    text-align: center;
  }
</style>
 <template>
  <div class="PlanejamentoAcaoList__Modal_Div" v-show="exibeConfiguracao">
    <v-card class="PlanejamentoAcaoList__Modal">
      <v-card-title>
        <span class="headline">
          {{ $t('title.configure_acao') }}
        </span>
      </v-card-title>
      <v-card-text class="my-0 py-0">
        <v-form ref="form" lazy-validation autocomplete="off">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" class="pb-0">
                <v-radio-group
                  id="modal_nova_acao_modalidade"
                  v-model="configuracaoAcaoVerbaVariavelPasso2.calculoBonificacao"
                  :label="`${$tc('title.modalidade', 1)} *`"
                  class="mt-0"
                  dense
                  @change="calculoBonificacaoAlterado"
                  :rules="[rules.required]">
                  <v-radio
                    v-for="(cb, index) in listaCalculosBonificacao"
                    :key="index"
                    class="my-0"
                    :label="`${$t('label.' + cb.toLowerCase())}`" :value="cb"></v-radio>
                </v-radio-group>
                <v-radio-group
                  id="modal_nova_acao_tipo_calculo"
                  v-if="!isCalculoBonificacaoValorFixoOrNotaFiscal"
                  v-model="configuracaoAcaoVerbaVariavelPasso2.formaBonificacao"
                  :label="`${$tc('label.tipo_calculo', 1)} *`"
                  class="mt-0"
                  dense
                  @change="bonificacaoAlterado"
                  :rules="[rules.required]">
                  <v-radio
                    v-for="(b, index) in listaBonificacoesFiltrada"
                    :key="index"
                    class="my-0"
                    :label="`${$t('label.' + b.toLowerCase())}`" :value="b"></v-radio>
                </v-radio-group>
                <v-radio-group
                  id="modal_nova_acao_possui_maximo_nd"
                  :disabled="isCalculoBonificacaoValorFixoOrNotaFiscal"
                  v-model="configuracaoAcaoVerbaVariavelPasso2.possuiValorMaximoNd"
                  @change="valorMaximoNdAlterado"
                  :label="`${$t('label.possui_valor_maximo_nd')}?`"
                  class="mt-0"
                  dense
                  :rules="[rules.required]">
                  <v-radio class="my-0" :label="$t('label.sim')" :value="true"></v-radio>
                    <v-radio-group
                      v-if="configuracaoAcaoVerbaVariavelPasso2.possuiValorMaximoNd && !isCalculoBonificacaoValorFixoOrNotaFiscal"
                      id="modal_tipo_calculo_valor_maximo_nd"
                      v-model="configuracaoAcaoVerbaVariavelPasso2.tipoCalculoValorMaximoND"
                      class="mt-0 ml-3"
                      dense
                      :rules="[rules.required]">
                      <v-row>
                        <v-col cols="12" md="9" class="mr-0">
                          <v-radio class="my-0" :label="$t('label.agrupamento_por_produto')" :value="'CALCULO_PRODUTO_ND_MAXIMO'"></v-radio>
                        </v-col>
                        <v-col cols="12" md="1" class="ml-0">
                          <tooltip-ajuda color="black" :mensagem="`${$t('message.ajuda.planejamento_acao.calculo_produto_nd_maximo')}`" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="8" class="mr-7">
                          <v-radio :label="$t('label.sobre_teto_da_campanha')" :value="'CALCULO_CAMPANHA_ND_MAXIMO'"></v-radio>
                        </v-col>
                        <v-col cols="12" md="1" class="ml-0">
                          <tooltip-ajuda color="black" :mensagem="`${$t('message.ajuda.planejamento_acao.calculo_campanha_nd_maximo')}`" />
                        </v-col>
                      </v-row>
                  </v-radio-group>
                  <v-radio class="my-0" :label="$t('label.nao')" :value="false"></v-radio>
                </v-radio-group>
                <v-radio-group
                  id="modal_nova_acao_possui_gatilho"
                  :disabled="isCalculoBonificacaoValorFixoOrNotaFiscal"
                  v-model="configuracaoAcaoVerbaVariavelPasso2.possuiGatilhoGerarRecebimento"
                  :label="`${$t('label.possui_gatilho_gerar_recebimento')}?`"
                  class="mt-0"
                  dense
                  @change="possuiGatilhoAlterado"
                  :rules="[rules.required]">
                  <v-radio class="my-0" :label="$t('label.sim')" :value="true"></v-radio>
                  <v-radio class="my-0" :label="$t('label.nao')" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="accent" dark @click.native="voltarPassoModal">{{ $t('label.anterior') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.native="avancarPassoModal">{{ configuracaoAcaoVerbaVariavelPasso2.possuiGatilhoGerarRecebimento
            ? $tc('label.proximo', 1) : $tc('label.criar', 1) }}</v-btn>
      </v-card-actions>
    </v-card>
    <confirm
      ref="confirmDialog"
      :message="$t('message.criar_acao')"
      :persistent="true"
      @agree="criarNovaAcao">
    </confirm>
  </div>
</template>
<script>
import Confirm from '@/shared-components/dialog/Confirm';
import TooltipAjuda from '@/shared-components/TooltipAjuda';

export default {
  props: {
    tipoAcaoSelecionada: {
      type: Object,
      default: {},
      required: true,
    },
  },
  components: {
    Confirm,
    TooltipAjuda,
  },
  watch: {
    tipoAcaoSelecionada() {
      this.listaCalculosBonificacao = [...this.tipoAcaoSelecionada.configuracao.calculosBonificacao.sort()];
      this.listaBonificacoes = [...this.tipoAcaoSelecionada.configuracao.bonificacoes.sort()];
      setTimeout(() => {
        if (this.listaCalculosBonificacao.length === 1) {
          [this.configuracaoAcaoVerbaVariavelPasso2.calculoBonificacao] = this.listaCalculosBonificacao;
        }
        if (this.listaBonificacoes.length === 1) {
          [this.configuracaoAcaoVerbaVariavelPasso2.formaBonificacao] = this.listaBonificacoes;
        }
        this.exibeConfiguracao = true;
      }, 500);
    },
  },
  computed: {
    isCalculoBonificacaoSellin() {
      return this.configuracaoAcaoVerbaVariavelPasso2.calculoBonificacao === 'SELLIN';
    },
    isCalculoBonificacaoSellout() {
      return this.configuracaoAcaoVerbaVariavelPasso2.calculoBonificacao === 'SELLOUT';
    },
    isCalculoBonificacaoValorFixoOrNotaFiscal() {
      return ['VALOR_FIXO', 'NOTA_FISCAL'].includes(this.configuracaoAcaoVerbaVariavelPasso2.calculoBonificacao);
    },
    possuiGatilho() {
      return this.configuracaoAcaoVerbaVariavelPasso2.possuiGatilhoGerarRecebimento;
    },
    verbaFixo() {
      return this.configuracaoAcaoVerbaVariavelPasso2.formaBonificacao === 'FIXO';
    },
    listaBonificacoesFiltrada() {
      if (!this.isCalculoBonificacaoValorFixoOrNotaFiscal && !this.possuiGatilho) {
        return this.listaBonificacoes.filter((b) => b !== 'FIXO');
      }
      return this.listaBonificacoes;
    },
  },
  data() {
    return {
      listaCalculosBonificacao: [],
      listaBonificacoes: [],
      exibeConfiguracao: false,
      configuracaoAcaoVerbaVariavelPasso2: {
        calculoBonificacao: null,
        formaBonificacao: null,
        possuiValorMaximoNd: false,
        possuiGatilhoGerarRecebimento: false,
      },
      rules: {
        required: (value) => (!!value || value === false) || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    calculoBonificacaoAlterado() {
      if (this.isCalculoBonificacaoValorFixoOrNotaFiscal) {
        this.configuracaoAcaoVerbaVariavelPasso2.formaBonificacao = null;
        this.configuracaoAcaoVerbaVariavelPasso2.possuiValorMaximoNd = false;
        this.configuracaoAcaoVerbaVariavelPasso2.possuiGatilhoGerarRecebimento = false;
      } else {
        this.configuracaoAcaoVerbaVariavelPasso2.formaBonificacao = null;
        this.configuracaoAcaoVerbaVariavelPasso2.possuiValorMaximoNd = false;
      }
    },
    bonificacaoAlterado() {
      if (this.verbaFixo) {
        this.configuracaoAcaoVerbaVariavelPasso2.possuiGatilhoGerarRecebimento = true;
      }
    },
    valorMaximoNdAlterado() {
      if (!this.configuracaoAcaoVerbaVariavelPasso2.possuiValorMaximoNd) {
        this.configuracaoAcaoVerbaVariavelPasso2.tipoCalculoValorMaximoND = '';
      }
    },
    possuiGatilhoAlterado() {
      if (this.verbaFixo) {
        this.configuracaoAcaoVerbaVariavelPasso2.formaBonificacao = null;
      }
    },
    resetConfiguracao() {
      this.configuracaoAcaoVerbaVariavelPasso2 = {
        calculoBonificacao: null,
        formaBonificacao: null,
        possuiValorMaximoNd: false,
        possuiGatilhoGerarRecebimento: false,
      };
      this.exibeConfiguracao = false;
      this.$refs.form.resetValidation();
    },
    voltarPassoModal() {
      this.$emit('PlanejamentoAcaoModalNovaAcao__VoltarPassoModal', 2);
    },
    avancarPassoModal() {
      if (!this.$refs.form.validate()) return;

      const params = {
        ...this.configuracaoAcaoVerbaVariavelPasso2,
        numPassoAtual: 2,
      };
      if (!this.configuracaoAcaoVerbaVariavelPasso2.possuiGatilhoGerarRecebimento) {
        this.abrirModalConfirmacao();
      } else {
        this.$emit('PlanejamentoAcaoModalNovaAcao__AvancarPassoModal', params);
      }
    },
    abrirModalConfirmacao() {
      this.$refs.confirmDialog.open();
    },
    criarNovaAcao() {
      if (!this.$refs.form.validate()) return;

      const params = {
        ...this.configuracaoAcaoVerbaVariavelPasso2,
        numPassoAtual: 2,
      };
      this.$emit('PlanejamentoAcaoModalNovaAcao__CriarNovaAcao', params);
    },
  },
  mounted() {
  },
};
</script>
